import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import {AuthService} from "./auth.service";


@Injectable({
  providedIn: "root"
})
export class TransfertService
 {
     currentEntite;
 
  constructor( private http: HttpClient,private auth: AuthService) {

      this.auth.currentEntity.subscribe(entite => {
          this.currentEntite = entite;
      });
  }


  getServiceEncaissement(data){
    return this.http.get<any[]>(environment.walletApi+ '/service_partenaires?service.codeService='+data.codeService);
  } 
  rechargementEncaissement(data){
    return this.http.post< any[]>(environment.walletApi + '/encaissements',data);
   }


  rechargementwallet(data){
    return this.http.post< any[]>(environment.walletApi + '/wallets',data);
   }

     banktowallet(data){
         return this.http.post< any[]>(environment.walletApi + '/bank/banktowallet',data);
     }

     wallettobank(data){
         return this.http.post< any[]>(environment.walletApi + '/bank/wallettobank',data);
     }
   rechargementCarte(data){
    return this.http.post< any[]>(environment.walletApi + '/recharges',data);
   }
   getFrais(params) {
    return this.http.post<any>(`${environment.walletApi}/service_partenaires/frais`, params)
  }
  

   historique(data){
    return this.http.get< any[]>(environment.walletApi + '/transactions/clients?_page='+data);
   }

   getServiceTransfertCode(){
    return this.http.get<any[]>(environment.walletApi+ '/service_partenaires/tranferts/code/cashout');
  }

  getTransfertCodeCashin() {
    return this.http.get<any[]>(environment.walletApi+ '/service_partenaires/tranferts/code/cashin');
  }

   transfertCode(data) {
     return this.http.post<any[]>(environment.walletApi + '/transferts/code', data);
   }

   rechargementCode(data) {
    return this.http.post<any[]>(environment.walletApi + '/transferts/code/reception', data);
  }
   transfertCodeCashin(data) {
    return this.http.post<any[]>(environment.walletApi + '/transferts/code/reception', data);
   }

   getServicetrsfWallet() {
     return this.http.get<any[]>(environment.walletApi + '/service_partenaires/wallets/cashin?pays='+this.currentEntite.pays);
   }
   getServiceRgtWallet(codePays) {
    return this.http.get<any[]>(environment.walletApi + '/service_partenaires/wallets/cashout?pays='+codePays);
  }
  
  getServiceAchatCredit(data) {
    return this.http.post<any[]>(environment.walletApi + '/airtime/releve', data);
  }

  AcheterCreditService(data) {
    return this.http.post<any[]>(environment.walletApi + '/air_times', data);
  }
  getServiceAbonnementTv() {
    return this.http.get<any[]>(environment.walletApi + '/service_partenaires/abonnements/tv');
  }
  getServicePaiementFactureEau() {
    return this.http.get<any[]>(environment.walletApi + '/service_partenaires/encaissements/eau');
  }
  getServicePmElectricite() {
    return this.http.get<any[]>(environment.walletApi + '/service_partenaires/encaissements/electricite');
   }
   getServiceRecharge() {
    return this.http.get<any[]>(environment.walletApi + '/service_partenaires/achats/cartes/recharge');
   }
}
